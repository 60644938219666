import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import Stat from "~components/atoms/stat/stat";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import HorizontalDivider from "~components/molecules/horizontal_divider/horizontal_divider";
import RichText from "~components/molecules/rich_text/rich_text";
import { BLOK_ARRAY, COLOR, RESPONSIVE_VALUE_NEW } from "../../../prop_types";
import DynamicArtworkComponent from "../../utils/dynamic_artwork_component/dynamic_artwork_component";
import { artworkWrapperStyle } from "./section_stats.module.scss";

function SectionStats({
  artwork: artworkArray,
  bodyRichText: bodyRichTextArray,
  cta: ctaArray,
  hasDivider,
  heading,
  headingColor,
  id,
  sectionSettings: sectionSettingsArray,
  stats,
  statsGridSplit,
  subscriptHeading,
  subscriptHeadingColor,
  ...rest
}) {
  const [artwork] = artworkArray || [];
  const [bodyRichText] = bodyRichTextArray || [];
  const [cta] = ctaArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain small>
        {/* ————— HEADINGS ————— */}
        {subscriptHeading && (
          <Heading
            {...rest}
            balanceText
            color={subscriptHeadingColor}
            isCentered
            isUppercase
            seoLevel={3}
            visualLevel={7}
          >
            {subscriptHeading}
          </Heading>
        )}
        {heading && (
          <Heading
            {...rest}
            balanceText
            isCentered
            color={headingColor}
            seoLevel={subscriptHeading ? 4 : 3}
            visualLevel={2}
          >
            {heading}
          </Heading>
        )}

        {/* ————— CONTENT ————— */}
        {bodyRichText && <RichText {...bodyRichText} />}
      </Contain>

      <Contain>
        {/* ———— STATS ————— */}
        {stats.length > 0 && (
          <GridNew split={statsGridSplit}>
            {stats.map((stat) => {
              return <Stat key={stat._uid} {...stat} />;
            })}
          </GridNew>
        )}

        {/* ———— ARTWORK ————— */}
        {artwork && (
          <div className={artworkWrapperStyle}>
            <DynamicArtworkComponent artwork={artwork} />
          </div>
        )}

        {/* ———— CTA ————— */}
        {cta && <CtaResponsive {...cta} />}

        {/* ———— DIVIDER ————— */}
        {hasDivider && <HorizontalDivider />}
      </Contain>
    </Section>
  );
}

SectionStats.propTypes = {
  artwork: BLOK_ARRAY,
  bodyRichText: BLOK_ARRAY,
  cta: BLOK_ARRAY,
  hasDivider: PropTypes.bool,
  heading: PropTypes.string,
  headingColor: PropTypes.oneOfType([COLOR, PropTypes.string]),
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  stats: BLOK_ARRAY,
  statsGridSplit: RESPONSIVE_VALUE_NEW,
  subscriptHeading: PropTypes.string,
  subscriptHeadingColor: PropTypes.oneOfType([COLOR, PropTypes.string]),
};

SectionStats.defaultProps = {
  artwork: null,
  bodyRichText: null,
  cta: null,
  hasDivider: false,
  heading: null,
  headingColor: null,
  id: null,
  sectionSettings: null,
  stats: null,
  statsGridSplit: null,
  subscriptHeading: null,
  subscriptHeadingColor: "grey",
};

export default SectionStats;
