import React from "react";
import PropTypes from "prop-types";
import { MEDIA } from "../../../prop_types";
import PhoneMockup from "../phone_mockup/phone_mockup";
import Video from "../video/video/video";
import { wrapperStyle } from "./video_with_device_frame.module.scss";

function VideoWithDeviceFrame({ video, poster, width }) {
  return (
    <div className={wrapperStyle}>
      <PhoneMockup>
        <Video video={video} poster={poster} width={width} />
      </PhoneMockup>
    </div>
  );
}

VideoWithDeviceFrame.propTypes = {
  video: MEDIA,
  poster: MEDIA,
  width: PropTypes.string,
};

VideoWithDeviceFrame.defaultProps = {
  video: null,
  poster: null,
  width: null,
};

export default VideoWithDeviceFrame;
