import { useEffect, useState } from "react";

function supportsWebm() {
  const video = document.createElement("video");
  return video.canPlayType("video/webm") === "maybe";
}

function supportsWebmAlpha() {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve) => {
    if (!supportsWebm()) {
      return resolve(false);
    }

    const video = document.createElement("video");
    video.muted = true;
    video.autoplay = true;
    video.loop = true;
    video.width = 64;
    video.height = 64;
    video.style.position = "absolute";
    video.style.opacity = 0;
    video.style.top = 0;

    const handleError = () => {
      resolve(false);
    };

    const handleTest = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = 64;
      canvas.height = 64;
      canvas.style.position = "absolute";
      canvas.style.top = 0;
      canvas.style.opacity = 0;

      document.body.appendChild(canvas);

      ctx.drawImage(video, 0, 0);
      const imageData = ctx.getImageData(0, 0, 1, 1);

      document.body.removeChild(canvas);

      // If pixel is semi-transparent (IE11 sometimes returns [0,0,0,0] for blank canvas) webm alpha is supported
      if (imageData.data[3] > 0 && imageData.data[3] < 255) {
        resolve(true);
      } else {
        resolve(false);
      }
    };

    const source = document.createElement("source");

    // prettier-ignore
    source.src = "data:video/webm;base64,GkXfowEAAAAAAAAfQoaBAUL3gQFC8oEEQvOBCEKChHdlYm1Ch4ECQoWBAhhTgGcBAAAAAAAC1hFNm3RAO027i1OrhBVJqWZTrIHlTbuMU6uEFlSua1OsggEjTbuMU6uEElTDZ1OsggFuTbuMU6uEHFO7a1OsggK57AEAAAAAAACbAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAVSalmAQAAAAAAADIq17GDD0JATYCNTGF2ZjU4LjIwLjEwMFdBjUxhdmY1OC4yMC4xMDBEiYhARAAAAAAAABZUrmsBAAAAAAAAP64BAAAAAAAANteBAXPFgQGcgQAitZyDdW5khoVWX1ZQOIOBASPjg4QCYloA4AEAAAAAAAAKsIFAuoFAU8CBARJUw2cBAAAAAAAAv3NzAQAAAAAAAC5jwAEAAAAAAAAAZ8gBAAAAAAAAGkWjh0VOQ09ERVJEh41MYXZmNTguMjAuMTAwc3MBAAAAAAAAOWPAAQAAAAAAAARjxYEBZ8gBAAAAAAAAIUWjh0VOQ09ERVJEh5RMYXZjNTguMzUuMTAwIGxpYnZweHNzAQAAAAAAADpjwAEAAAAAAAAEY8WBAWfIAQAAAAAAACJFo4hEVVJBVElPTkSHlDAwOjAwOjAwLjA0MDAwMDAwMAAAH0O2dQEAAAAAAAB054EAoAEAAAAAAABooauBAAAAEAMAnQEqQABAAAAHCIWFiIWEiAAoEAAzgeISYwOQ8e7bAP7/q1CAdaEBAAAAAAAAMaYBAAAAAAAAKO6BAaWjEAMAnQEqQABAAAAHCIWFiIWEiAAoEAAwy0ikCK4ItQiIAAAcU7trAQAAAAAAABG7j7OBALeK94EB8YICOfCBAw==";

    video.addEventListener("loadeddata", handleTest, false);
    video.addEventListener("error", handleError);
    video.addEventListener("stalled", handleError);
    video.addEventListener("abort", handleError);
    source.addEventListener("error", handleError);

    video.appendChild(source);
  });
}

let cache;

export default function useSupportsWebm() {
  const [supports, setSupports] = useState();

  useEffect(() => {
    if (!cache) {
      cache = supportsWebmAlpha();
    }
    cache.then(setSupports);
  }, []);

  return [supports];
}
