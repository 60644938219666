import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Avatar from "~components/molecules/avatar/avatar";
import BackgroundImage from "~components/molecules/background_image/background_image";
import { BLOK_ARRAY } from "../../../../prop_types";
import {
  authorNameStyle,
  clientLogoStyle,
  isXLStyle,
  quoteAttributionStyle,
  quoteBodyStyle,
  textColorDarkStyle,
  textColorLightStyle,
} from "./quote_small_left_aligned.module.scss";

export default function QuoteSmallLeftAligned({
  quoteBody,
  quoteAttribution: quoteAttributionArray,
  theme,
  isXL,
  ...rest
}) {
  const [quoteAttribution] = quoteAttributionArray || [];

  const quoteBodyClassNames = classNames(quoteBodyStyle, {
    [textColorDarkStyle]: theme === "dark",
    [textColorLightStyle]: theme === "light",
    [isXLStyle]: isXL,
  });

  if (quoteBody || quoteAttribution) {
    return (
      <SbEditable content={rest}>
        {quoteBody && (
          <blockquote className={quoteBodyClassNames} data-testid="quote">
            <p>{quoteBody}</p>
          </blockquote>
        )}
        {quoteAttribution && (
          <SbEditable content={{ ...quoteAttribution }}>
            <div
              className={quoteAttributionStyle}
              data-testid="quote-attribution"
            >
              {quoteAttribution.authorAvatar && (
                <Avatar {...quoteAttribution.authorAvatar[0]} width={64} />
              )}
              <div>
                {quoteAttribution.authorName && (
                  <span
                    className={authorNameStyle}
                    data-testid="quote-attribution-author-name"
                    itemProp="name"
                  >
                    {quoteAttribution.authorName}
                    {quoteAttribution.authorName &&
                      quoteAttribution.authorRole &&
                      ", "}
                  </span>
                )}
                {quoteAttribution.authorRole && (
                  <span
                    data-testid="quote-attribution-author-role"
                    itemProp="jobTitle"
                  >
                    {quoteAttribution.authorRole}
                  </span>
                )}
                {quoteAttribution.clientLogo.length > 0 && (
                  <div className={clientLogoStyle} data-testid="client-logo">
                    <BackgroundImage
                      {...quoteAttribution.clientLogo[0]}
                      width={null}
                      horizontalAlign="left"
                    />
                  </div>
                )}
              </div>
            </div>
          </SbEditable>
        )}
      </SbEditable>
    );
  }
  return null;
}

QuoteSmallLeftAligned.defaultProps = {
  quoteBody: null,
  quoteAttribution: null,
  theme: "dark",
  isXL: false,
};

QuoteSmallLeftAligned.propTypes = {
  quoteBody: PropTypes.string,
  quoteAttribution: BLOK_ARRAY,
  theme: PropTypes.string,
  isXL: PropTypes.bool,
};
