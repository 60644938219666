import React from "react";
import PropTypes from "prop-types";
import GridNew from "~components/atoms/grid_new/grid_new";
import Image from "~components/atoms/image/image";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import HorizontalDivider from "~components/molecules/horizontal_divider/horizontal_divider";
import NavLink from "~components/molecules/nav_link/nav_link";
import QuoteSmallLeftAligned from "~components/molecules/quote/quote_small_left_aligned/quote_small_left_aligned";
import RichText from "~components/molecules/rich_text/rich_text";
import TickList from "~components/molecules/tick_list/tick_list";
import {
  BLOK,
  BLOK_ARRAY,
  RESPONSIVE_VALUE_NEW,
} from "../../../../../prop_types";
import { navLinkWrapperStyle } from "./section_alternating_layout_body.module.scss";

function SectionAlternatingLayoutBody({
  bodyRichText,
  cta,
  logos,
  logosGridSplit,
  logosTitle,
  productLinks,
  quote,
  tickList,
}) {
  return (
    <>
      {/* Content section */}
      {bodyRichText && <RichText {...bodyRichText} />}
      {tickList && <TickList {...tickList} />}
      {/* Quote section */}
      {quote && <QuoteSmallLeftAligned {...quote} />}
      {/* Product links section */}
      {productLinks.length > 0 && (
        <div className={navLinkWrapperStyle}>
          {productLinks.map((link) => {
            return <NavLink {...link} key={link._uid} />;
          })}
        </div>
      )}
      {/* Cta section */}
      {cta && <CtaResponsive {...cta} />}
      {/* Logos section */}
      {logosTitle && <HorizontalDivider text={logosTitle} noMargin />}
      {logos && logos.length > 0 && (
        <GridNew split={logosGridSplit}>
          {logos.map((logo) => {
            return <Image key={logo._uid} {...logo} />;
          })}
        </GridNew>
      )}
    </>
  );
}

SectionAlternatingLayoutBody.propTypes = {
  bodyRichText: BLOK,
  cta: BLOK,
  logos: BLOK_ARRAY,
  logosGridSplit: RESPONSIVE_VALUE_NEW,
  logosTitle: PropTypes.string,
  productLinks: BLOK_ARRAY,
  quote: BLOK,
  tickList: BLOK,
};

SectionAlternatingLayoutBody.defaultProps = {
  bodyRichText: null,
  cta: null,
  logos: null,
  logosGridSplit: null,
  logosTitle: null,
  productLinks: null,
  quote: null,
  tickList: null,
};

export default SectionAlternatingLayoutBody;
