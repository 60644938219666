import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import { COLOR } from "../../../prop_types";
import Heading from "../heading/heading";
import { wrapperStyle } from "./stat.module.scss";

export default function Stat({
  text,
  color,
  descriptionColor,
  descriptionText,
  ...rest
}) {
  return (
    <SbEditable content={rest}>
      <div className={wrapperStyle}>
        <Heading isCentered color={color} visualLevel={1} seoLevel={3}>
          {text}
        </Heading>
        <Heading
          balanceText
          isCentered
          color={descriptionColor}
          isBaseFontWeight
          visualLevel={6}
          seoLevel={4}
        >
          {descriptionText}
        </Heading>
      </div>
    </SbEditable>
  );
}

Stat.propTypes = {
  text: PropTypes.string,
  descriptionText: PropTypes.string,
  color: COLOR,
  descriptionColor: PropTypes.string,
};

Stat.defaultProps = {
  descriptionText: null,
  descriptionColor: "cloudBurst",
  text: "",
  color: null,
};
