import React from "react";
import PropTypes from "prop-types";
import * as classes from "./phone_mockup.module.scss";

export default function PhoneMockup({ children }) {
  return (
    <div className={classes.phoneMockup}>
      <div className={classes.device}>
        <div className={classes.asset}>{children}</div>
      </div>
    </div>
  );
}

PhoneMockup.defaultProps = {
  children: null,
};

PhoneMockup.propTypes = {
  children: PropTypes.node,
};
