import React from "react";
import PropTypes from "prop-types";
import Image from "~components/atoms/image/image";
import Video from "~components/molecules/video/video/video";
import { isRetina } from "~utils/retina_utils/retina_utils";
import useSupportsWebm from "~hooks/use_supports_webm/use_supports_webm";
import { BLOK_ARRAY, MEDIA } from "../../../prop_types";
import { wrapperStyle } from "./transparent_video_retina.module.scss";

export default function TransparentVideoRetina({
  video1x,
  video2x,
  fallbackImage,
  preload,
  ...rest
}) {
  const [supports] = useSupportsWebm();

  if (supports) {
    return (
      <div className={wrapperStyle} data-testid="video-retina-wrapper">
        {video1x && video2x && supports === true && (
          <Video
            poster={fallbackImage}
            video={isRetina() ? video2x : video1x}
            {...rest}
          />
        )}
      </div>
    );
  }

  return (
    <div className={wrapperStyle} data-testid="video-retina-wrapper">
      <Image image={fallbackImage} {...rest} />
    </div>
  );
}

TransparentVideoRetina.defaultProps = {
  fallbackImage: null,
  settings: null,
  preload: false,
};

TransparentVideoRetina.propTypes = {
  video1x: MEDIA.isRequired,
  video2x: MEDIA.isRequired,
  fallbackImage: MEDIA,
  settings: BLOK_ARRAY,
  preload: PropTypes.bool,
};
