import React from "react";
import Image from "~components/atoms/image/image";
import PhoneMockup from "~components/molecules/phone_mockup/phone_mockup";
import TransparentVideoRetina from "~components/molecules/transparent_video_retina/transparent_video_retina";
import Video from "~components/molecules/video/video/video";
import VideoWithDeviceFrame from "~components/molecules/video_with_device_frame/video_with_device_frame";
import { BLOK } from "../../../prop_types";

function DynamicArtworkComponent({ artwork }) {
  // ToDo: consider a way to optimise the import of this component
  // perhaps trying loadable components makes sense here?

  if (artwork?.component === "image") {
    return <Image {...artwork} />;
  }
  if (artwork?.component === "transparentVideoRetina") {
    return <TransparentVideoRetina {...artwork} />;
  }
  if (artwork?.component === "videoWithDeviceFrame") {
    return <VideoWithDeviceFrame {...artwork} />;
  }
  if (artwork?.component === "phoneMockup") {
    return (
      <PhoneMockup>
        {artwork.asset[0].component === "image" && (
          <Image {...artwork.asset[0]} />
        )}
        {artwork.asset[0].component === "video" && (
          <Video {...artwork.asset[0]} />
        )}
      </PhoneMockup>
    );
  }
  if (artwork?.component === "video") {
    return <Video {...artwork} />;
  }
  return null;
}

DynamicArtworkComponent.propTypes = {
  artwork: BLOK,
};

DynamicArtworkComponent.defaultProps = {
  artwork: null,
};

export default DynamicArtworkComponent;
