import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import BackgroundImage from "~components/molecules/background_image/background_image";
import DynamicArtworkComponent from "~components/utils/dynamic_artwork_component/dynamic_artwork_component";
import useMatchMedia from "~hooks/use_match_media/use_match_media";
import { BLOK_ARRAY, RESPONSIVE_VALUE_NEW } from "../../../prop_types";
import SectionAlternatingLayoutBody from "./components/section_alternating_layout_body/section_alternating_layout_body";
import * as classes from "./section_alternating_layout.module.scss";

function SectionAlternatingLayout({
  artwork: artworkArray,
  bodyRichText: bodyRichTextArray,
  cta: ctaArray,
  heading: headingArray,
  id,
  isReversed,
  logos,
  logosGridSplit,
  logosTitle,
  productLinks,
  quote: quoteArray,
  ratio,
  subscriptHeading,
  artworkBackgroundImage: [artworkBackgroundImage],
  sectionSettings: sectionSettingsArray,
  tickList: tickListArray,
  ...rest
}) {
  const shouldUseColumnLayout = useMatchMedia({ max: "M" });
  const [artwork] = artworkArray || [];
  const [bodyRichText] = bodyRichTextArray || [];
  const [heading] = headingArray || [];
  const [cta] = ctaArray || [];
  const [quote] = quoteArray || [];
  const [sectionSettings] = sectionSettingsArray || [];
  const [tickList] = tickListArray || [];

  const classNames = [
    classes.alternatingLayoutItem,
    classes[`ratio_${ratio}`],
    isReversed ? classes.isReversed : "",
  ];

  return (
    <Section id={id} sectionSettings={sectionSettings} {...rest}>
      <Contain>
        <div className={classNames.join(" ")}>
          <div className={classes.copyWrapper}>
            {/* ————— HEADINGS ————— */}
            {subscriptHeading && (
              <Heading
                {...rest}
                balanceText
                color="gray"
                isUppercase
                seoLevel={3}
                visualLevel={7}
              >
                {subscriptHeading}
              </Heading>
            )}
            {heading && <Heading {...heading} balanceText />}

            {/* ————— BODY (DESKTOP) ————— */}
            {!shouldUseColumnLayout && (
              <SectionAlternatingLayoutBody
                bodyRichText={bodyRichText}
                cta={cta}
                logos={logos}
                quote={quote}
                logosGridSplit={logosGridSplit}
                logosTitle={logosTitle}
                productLinks={productLinks}
                tickList={tickList}
              />
            )}
          </div>

          {/* ————— ARTWORK ————— */}

          <div className={classes.artworkWrapper}>
            <div className={classes.artwork}>
              {artwork && <DynamicArtworkComponent artwork={artwork} />}
              {artworkBackgroundImage && (
                <BackgroundImage
                  verticalAlign="middle"
                  {...artworkBackgroundImage}
                />
              )}
            </div>
          </div>

          {/* ————— BODY (MOBILE) ————— */}
          {shouldUseColumnLayout && (
            <div className={classes.copyWrapper}>
              <SectionAlternatingLayoutBody
                bodyRichText={bodyRichText}
                cta={cta}
                logos={logos}
                quote={quote}
                logosGridSplit={logosGridSplit}
                logosTitle={logosTitle}
                productLinks={productLinks}
                tickList={tickList}
              />
            </div>
          )}
        </div>
      </Contain>
    </Section>
  );
}

SectionAlternatingLayout.propTypes = {
  artwork: BLOK_ARRAY,
  bodyRichText: BLOK_ARRAY,
  cta: BLOK_ARRAY,
  heading: BLOK_ARRAY,
  id: PropTypes.string,
  isReversed: PropTypes.bool,
  logos: BLOK_ARRAY,
  artworkBackgroundImage: BLOK_ARRAY,
  logosGridSplit: RESPONSIVE_VALUE_NEW,
  logosTitle: PropTypes.string,
  productLinks: BLOK_ARRAY,
  quote: BLOK_ARRAY,
  ratio: PropTypes.oneOf(["6_6", "5_7", "4_8"]),
  sectionSettings: BLOK_ARRAY,
  subscriptHeading: PropTypes.string,
  tickList: BLOK_ARRAY,
};

SectionAlternatingLayout.defaultProps = {
  artwork: null,
  bodyRichText: null,
  cta: null,
  heading: null,
  id: null,
  isReversed: false,
  artworkBackgroundImage: [],
  logos: null,
  logosGridSplit: null,
  logosTitle: null,
  productLinks: false,
  quote: null,
  ratio: "6_6",
  sectionSettings: null,
  subscriptHeading: null,
  tickList: null,
};

export default SectionAlternatingLayout;
